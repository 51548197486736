let authAPI = '/api/auth';
let dashboardApi = '/api/dashboard';
let userAPI = '/api/users';
let crudMasterApi = '/api/masters';
let DeviceMasterApi = '/api/devicemasters';

let clientApi = '/api/clients';


export default {
    auth: {
	loginterms: authAPI + '/loginterms',
        login: authAPI + '/login', //POST
        logout: authAPI + '/logout', //POST
        userfromtoken: authAPI + '/userfromtoken',
	sideMenu: authAPI + '/sidemenu',
	privilege: authAPI + '/privilege',
	moduleRolePrivilege: authAPI + '/moduleroleprivilege'
    },
    dashboard: {
	totals : dashboardApi + '/totals',
	latestdevices: dashboardApi + '/latestdevices',
	topclients: dashboardApi + '/topclients',
    },
    configuration: {
        organization: crudMasterApi + '/Organization',
        outgoingmail: crudMasterApi + '/OutgoingMailServer',
        currency: crudMasterApi + '/Currency',
        termsandcondition: crudMasterApi + '/MatexTc'
    },
    masters: {
        commonCrudService: crudMasterApi,
        location: crudMasterApi + '/Location',
        country: crudMasterApi + '/country',
        state: crudMasterApi + '/state',
        district: crudMasterApi + '/district',
        city: crudMasterApi + '/city',
        region: crudMasterApi + '/region',
        industry: crudMasterApi + '/industry',
    },
    devicemasters: {
        commonCrudService: DeviceMasterApi,
        alarmtype: DeviceMasterApi + '/alarmtype',
        instrumenttype: DeviceMasterApi + '/instrumenttype',
        instrumentchannel: DeviceMasterApi + '/instrumentchannel',
	gasunit: DeviceMasterApi + '/gasunit',
	datainterval: DeviceMasterApi + '/datainterval',
    },
    clients: {
        client: clientApi + '/client',
        device :clientApi + '/device',
	deviceconfiguration :clientApi + '/deviceconfiguration',  
        clientdevicelocation :clientApi + '/clientdevicelocation',
	clientdevicedata :clientApi + '/clientdevicelocation/data',
	clientdevicedashboardlive :clientApi + '/clientdevicelocation/dashboard/live',
	clientdevicedashboardlivedata :clientApi + '/clientdevicelocation/dashboard/livedata',
	clientdevicedashboardlivetrend :clientApi + '/clientdevicelocation/dashboard/livetrend',
	clientrole: clientApi + '/clientrole',
	clientuser: clientApi + '/clientuser',
    },
    users: {
         user: userAPI + '/user', 
        //searchusers: userAPI + '/search', //Post
        //matexusers: userAPI + '/user',
        //roles: rbacAPI + '/role',
//        roleprivilege: rbacAPI + '/roleprivilege',
//        roleprivilegeMenuHeaders: rbacAPI + '/roleprivilege/menu?level=0&isHeader=true',
        //passwordpolicy: crudMasterApi + '/PasswordPolicy',
       
        userRole: userAPI + '/userrole',
       
    },

};

