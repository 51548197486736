import React, { lazy } from 'react';
import MainLayout from 'layout/MainLayout';
import AuthGuard from 'component/Auth/AuthGuard';
import Loadable from 'component/Loadable';

const DashboardDefault = Loadable(lazy(() => import('views/Dashboard/Default')));

const GeneralMasters = Loadable(lazy(() => import('views/GeneralMasters')));

const Configuration = Loadable(lazy(() => import('views/Configuration')));
const Users = Loadable(lazy(() => import('views/Users')));
const Clients = Loadable(lazy(() => import('views/Clients')));


const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/dashboard/default',
            element: <DashboardDefault />,
        },
        {
            path: '/generalmasters/*',
            element: <GeneralMasters />,
        },
        {
            path: '/clients/*',
            element: <Clients />,
        },
        {
            path: '/users/*',
            element: <Users />,
        },
        {
            path: '/configuration/*',
            element: <Configuration />,
        },
    ],
};

export default MainRoutes;
