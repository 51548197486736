import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'; //Dashboard
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined'; // Client
import CasesOutlinedIcon from '@mui/icons-material/CasesOutlined'; //Case registration
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'; //User
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined'; // General Masters
import SettingsApplicationsSharpIcon from '@mui/icons-material/SettingsApplicationsSharp'; // Configuration

export default {
    HomeOutlinedIcon: HomeOutlinedIcon,
    AccountCircleOutlinedIcon: AccountCircleOutlinedIcon,
    CorporateFareOutlinedIcon: CorporateFareOutlinedIcon,
    AccountTreeOutlinedIcon: AccountTreeOutlinedIcon,
    SettingsApplicationsSharpIcon: SettingsApplicationsSharpIcon,
};
