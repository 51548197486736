/*
import NavigationOutlinedIcon from '@mui/icons-material/NavigationOutlined';

import FormatUnderlinedOutlinedIcon from '@mui/icons-material/FormatUnderlinedOutlined';
import GamesOutlinedIcon from '@mui/icons-material/GamesOutlined';
import LoyaltyOutlinedIcon from '@mui/icons-material/LoyaltyOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';

import CallToActionOutlinedIcon from '@mui/icons-material/CallToActionOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import FilterVintageOutlinedIcon from '@mui/icons-material/FilterVintageOutlined';
import FormatColorTextOutlinedIcon from '@mui/icons-material/FormatColorTextOutlined';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import ChromeReaderModeOutlinedIcon from '@mui/icons-material/ChromeReaderModeOutlined';
import GridOnOutlinedIcon from '@mui/icons-material/GridOnOutlined';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import EditAttributesOutlinedIcon from '@mui/icons-material/EditAttributesOutlined';
import FaceOutlinedIcon from '@mui/icons-material/FaceOutlined';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';

import CardGiftcardOutlinedIcon from '@mui/icons-material/CardGiftcardOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import SpeakerNotesOutlinedIcon from '@mui/icons-material/SpeakerNotesOutlined';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import TranslateIcon from '@mui/icons-material/Translate';
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';
import EventNoteIcon from '@mui/icons-material/EventNote';
import DoneAllIcon from '@mui/icons-material/DoneAll';
*/

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import CasesOutlinedIcon from '@mui/icons-material/CasesOutlined'; //Case registration
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined'; //Data Entry
import DomainVerificationOutlinedIcon from '@mui/icons-material/DomainVerificationOutlined'; //Verification / Operations
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined'; //Report
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'; //User
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined'; //Client
import DoneAllIcon from '@mui/icons-material/DoneAll';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined'; // General Masters
import SettingsApplicationsSharpIcon from '@mui/icons-material/SettingsApplicationsSharp'; // Configuration
import SellIcon from '@mui/icons-material/Sell';

const icons = {
    DashboardIcon: HomeOutlinedIcon,
    CasesRegistrationIcon: CasesOutlinedIcon,
    DataEntryIcon: StorageOutlinedIcon,
    OperationsIcon: DomainVerificationOutlinedIcon,
    ReportsIcon: InsertChartOutlinedIcon,
    UserIcon: AccountCircleOutlinedIcon,
    ClientIcon: CorporateFareOutlinedIcon,
    CheckMasters: DoneAllIcon,
    GeneralMastersIcon: AccountTreeOutlinedIcon,
    SettingsApplicationsSharpIcon: SettingsApplicationsSharpIcon,
    SellIcon: SellIcon
};

export default {
    items: [
        {
            id: 'navigation',
            title: 'Unisearch',
            caption: '',
            type: 'group',
            icon: icons[''],
            children: [
                {
                    id: 'dashboard',
                    title: 'Dashboard',
                    type: 'item',
                    icon: icons['DashboardIcon'],
                    url: '/dashboard/default',
                },
            ],
        },
	        {
            id: 'clientmasters',
            title: 'Clients',
            caption: '',
            type: 'group',
            icon: icons[''],
            children: [
                {
                    id: 'client',
                    title: 'Client',
                    type: 'collapse',
                    icon: icons['ClientIcon'],
                    children: [
                        {
                            id: 'client',
                            title: 'Client',
                            type: 'item',
                            url: '/clients/client',
                        },
                        {
                            id: 'clientdevice',
                            title: 'Client Devices',
                            type: 'item',
                            url: '/clients/clientdevice',
                        },
			{
                            id: 'clientuser',
                            title: 'Client Users',
                            type: 'item',
                            url: '/clients/clientusers',
                        },
                    ],
                },
            ],
        },
        {
            id: 'usermasters',
            title: 'Users & Privileges',
            caption: '',
            type: 'group',
            icon: icons[''],
            children: [
                {
                    id: 'user',
                    title: 'Users',
                    type: 'collapse',
                    icon: icons['UserIcon'],
                    children: [
                        {
                            id: 'userroles',
                            title: 'User Roles',
                            type: 'item',
                            url: '/users/userroles',
                        },
                        {
                            id: 'user',
                            title: 'Users',
                            type: 'item',
                            url: '/users/user',
                        },

                    ],
                },
            ],
        },
        {
            id: 'masters',
            title: 'Masters',
            caption: '',
            type: 'group',
            icon: icons[''],
            children: [
                {
                    id: '',
                    title: 'General Masters',
                    type: 'collapse',
                    icon: icons['GeneralMastersIcon'],
                    children: [
                        {
                            id: 'locations',
                            title: 'Location Masters',
                            type: 'item',
                            url: '/generalmasters/locations',
                        },
                        {
                            id: 'Device Masters',
                            title: 'Device Masters',
                            type: 'item',
                            url: '/generalmasters/devicemasters',
                        },
                    ],
                },
            ],
        },
    ],
};
