/**
 *   @File        : Fields.js
 *
 *   @module      : Component
 *
 *   @purpose     : This file is contains all MUI Fields to be used with hook form
 *
 *   @author      : Kishore S(kishore@likemindtech.com)
 * *********************************************************************
 *        Copyright (C) Nilgiris Likemind Technologies, 2022-2023
 *                       All Rights Reserved.
 *    The copying, invocation or execution of this product constitutes
 *    full knowledge of Likemind Technologies rights and agreement
 *    to its licensing policies.
 * *********************************************************************
 */

import React, { useState } from "react";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Alert,
  IconButton,
  Collapse,
  Snackbar,
  Box,
  Button,
  Stack,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  createFilterOptions,
  Autocomplete,
  Typography,
  Divider,
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  Paper,
  Switch
} from "@mui/material";

import { Controller, useForm } from "react-hook-form";

import CloseIcon from "@mui/icons-material/Close";
import ClearTwoToneIcon from '@mui/icons-material/ClearTwoTone';
import {
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";

import {
  LocalizationProvider,
    DatePicker,
    DateTimePicker,
    TimeField
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";
import { axiosClient } from "utils/apiClient";

import AttachFileIcon from "@mui/icons-material/AttachFile";

export function InputText(props) {
  const {
    control,
    name,
    required,
    pattern,
    errormessage,
    sx,
    customOnChange,
    errors,
    value,
    multiline,
    label,
    readOnly,
    helperText,
    type,
    size
  } = props;
  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={{
          required: required,
          pattern: {
            value: pattern || null,
            message: errormessage || null,
            // value:  /^\S(.*\S)?$/,
            // message: 'No leading and trailing space'
          }
        }}
        defaultValue={value || null}
        render={({ field }) => (
          <TextField
            {...field}
            {...sx}
            onChange={(event) => {
              field.onChange(event.target.value);
              if (customOnChange) {
                customOnChange(event.target.value);
              }
            }}
            autoComplete="off"
            error={errors[name] ? true : false}
            type={type ? type : "text"}
            size={size ? size : "large"}
            fullWidth
            multiline={multiline ? true : false}
            rows={multiline ? "3" : ""}
            value={
              field.value === undefined
                ? ""
                : typeof field.value === "object"
                  ? ""
                  : field.value
            }
            label={required ? label + " *" : label}
            InputProps={{
              style: { borderRadius: "8px" },
              readOnly: readOnly === true ? true : false,
            }}
            inputProps={{
              min: 0,
            }}
            InputLabelProps={{
              //shrink: true,
            }}
            helperText={
              errors[name]?.type === "serverError"
                ? errors[name]?.message
                : errors[name]?.type === "required"
                  ? label + " required"
                  : errors[name]?.type === "pattern"
                    ? errors[name]?.message
                    : errors[name]?.type === "min"
                      ? errors[name]?.message
                      : errors[name]?.type === "oneOf"
                        ? errors[name]?.message
                        : errors[name]?.type === "typeError"
                          ? label + " required"
                          : errors[name]?.type === "matches"
                            ? errors[name]?.message
                            : helperText ? helperText : ''
            }
          />
        )}
      />
    </>
  );
}

const FileInput = (props) => {
  return (
    <>
      <label style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '300px',
      }}>
        {/* <span
          className={props?.isplaceholder ? "MuiFileInput-placeholder" : ""}
          style={{
            position: "absolute",
            zIndex: 2,
            userSelect: "none",
            whiteSpace: 'nowrap',
            textOverflow: "ellipsis",
          }}
        > */}
        {props?.text}
        {/* </span> */}
      </label>
      <input
        {...props}
        type="file"
        id={props?.id}
        accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
 text/plain, application/pdf, image/*, .txt"
      />




    </>
  );
};

export function FileUpload({
  control,
  required,
  name,
  setValue,
  label,
  errors,
  readOnly,
  placeholder,
  sx,
  multiple,
  helperText
}) {
  const [fileValue, setFileValue] = useState([]);
  const [fileSize, setFileSize] = useState(0);

  function GetFileName() {
    if (fileValue?.length > 1) {
      return fileValue?.length + " files are choosen";
    } else if (fileValue?.length === 1) {
      return fileValue[0]?.name;
    }
  }
  function GetFileSizes() {
    var TotalSize = 0;
    Object.entries(fileValue)?.forEach((item) => {
      TotalSize = TotalSize + item[1].size;
      setFileSize(TotalSize)
    });
    return fileSize
  }
  function ClearFiles() {
    if (fileValue?.length > 0) {
      setFileValue([]);
      setValue(name, "");
      setFileSize(0)
    }
  }
  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={{
          required: required,
        }}
        render={({ field: { value, onChange } }) => {
          return (
            <TextField
              {...sx}
              onChange={(event) => {
                onChange(event.target.files);
                setFileValue(event.target.files);
              }}
              type="file"
              fullWidth
              sx={{
                "& .MuiInputBase-input": {
                  opacity: "0 !important",
                },
              }}
              className={`MuiFileInput-TextField`}
              error={errors[name] ? true : false}
              label={required ? label + " *" : label}
              InputProps={{
                style: { borderRadius: "8px" },
                readOnly: readOnly === true ? true : false,
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachFileIcon />
                  </InputAdornment>
                ),
                inputProps: {
                  multiple: multiple ? true : false,
                  type: "file",
                  isplaceholder: true,
                  text: value === null || value === "" || value === undefined ? placeholder : GetFileName(),
                },
                inputComponent: FileInput,
                endAdornment: (
                  <>
                    <InputAdornment position="end">
                      <Typography
                        variant="caption"
                        mr="2px"
                        className="MuiFileInput-Typography-size-text"
                      >
                        {value ? GetFileSizes() : '0'}
                      </Typography>
                      <IconButton
                        aria-label="Clear"
                        title="Clear"
                        size="small"
                        disabled={false}
                        className="MuiFileInput-IconButton"
                        onClick={ClearFiles}
                      >
                        <ClearTwoToneIcon />
                      </IconButton>
                    </InputAdornment>
                  </>
                ),
              }}
              helperText={
                errors[name]?.type === "serverError"
                  ? errors[name]?.message
                  : errors[name]?.type === "required"
                    ? label + " required"
                    : errors[name]?.type === "pattern"
                      ? errors[name]?.message
                      : errors[name]?.type === "min"
                        ? errors[name]?.message
                        : errors[name]?.type === "oneOf"
                          ? errors[name]?.message
                          : helperText ? helperText : ''
              }
            />
          );
        }}
      />
    </>
  );
}

export function PasswordInputText({
  control,
  name,
  required,
  sx,
  label,
  errors,
  readOnly,
  customOnChange,
  helperText
}) {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={{
          required: required,
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            {...sx}
            error={errors[name] ? true : false}
            type={showPassword ? "text" : "password"}
            fullWidth={true}
            value={
              field.value === undefined
                ? ""
                : typeof field.value === "object"
                  ? ""
                  : field.value
            }
            onChange={(event) => {
              field.onChange(event.target.value);
              if (customOnChange) {
                customOnChange(event.target.value);
              }
            }}
            label={required ? label + " *" : label}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
              style: { borderRadius: "10px" },
              readOnly: readOnly === true ? true : false,
            }}
            inputProps={{
              min: 0,
            }}
            InputLabelProps={{
              //shrink: true,
            }}
            helperText={
              errors[name]?.type === "serverError"
                ? errors[name]?.message
                : errors[name]?.type === "required"
                  ? label + " required"
                  : errors[name]?.type === "pattern"
                    ? errors[name]?.message
                    : errors[name]?.type === "min"
                      ? errors[name]?.message
                      : errors[name]?.type === "oneOf"
                        ? errors[name]?.message
                        : errors[name]?.type === "notOneOf"
                          ? errors[name]?.message
                          : helperText ? helperText : ''
            }
          />
        )}
      />
    </>
  );
}
export function InputAdornmentText({
  control,
  name,
  required,
  sx,
  label,
  errors,
  readOnly,
  customOnChange,
  helperText,
  CustomAdornment,
  type
}) {
  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={{
          required: required,
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            {...sx}
            error={errors[name] ? true : false}
            type={type ? type : "text"}
            fullWidth={true}
            autoComplete="off"
            value={
              field.value === undefined
                ? ""
                : typeof field.value === "object"
                  ? ""
                  : field.value
            }
            onChange={(event) => {
              field.onChange(event.target.value);
              if (customOnChange) {
                customOnChange(event.target.value);
              }
            }}
            label={required ? label + " *" : label}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CustomAdornment />
                </InputAdornment>
              ),
              style: { borderRadius: "10px" },
              readOnly: readOnly === true ? true : false,
            }}
            inputProps={{
              min: 0,
            }}
            InputLabelProps={{
              //shrink: true,
            }}
            helperText={
              errors[name]?.type === "serverError"
                ? errors[name]?.message
                : errors[name]?.type === "required"
                  ? label + " required"
                  : errors[name]?.type === "pattern"
                    ? errors[name]?.message
                    : errors[name]?.type === "min"
                      ? errors[name]?.message
                      : errors[name]?.type === "oneOf"
                        ? errors[name]?.message
                        : helperText ? helperText : ''
            }
          />
        )}
      />
    </>
  );
}


//
export function SelectInput({
  optionLabel,
  required,
  control,
  errors,
  name,
  options,
  label,
  customOnChange,
  helperText,
  readOnly,
  onChangeNeededFields,
  width,
  size
}) {

  const style = {
    backgroundColor: 'rgba(240, 247, 251, 0.75)',
    backdropFilter: 'blur(16px) saturate(180%)',
    color: 'black',
    fontWeight: '500px'
  };
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: required,
      }}
      render={({
        field: { onChange, value },
      }) => {
        return (
          <Autocomplete
            //Here never use the onInputChange
            options={options || []}
            getOptionLabel={(option) => {
              return option[optionLabel] ? option[optionLabel] : "";
            }}
            readOnly={readOnly ? readOnly : false}
            // key={success}
            getOptionSelected={(option, value) => {
              return option.id === value.id;
            }}
            PaperComponent={({ children }) => (
              // background: "#F0F7FB"
              <Paper style={style}>{children}</Paper>
            )}
            value={value ?? ""}
            inputValue={value?.[optionLabel] ?? value ?? ""}
            fullWidth={width ? false : true}
            sx={{
              width: width ? width : 'default',
            }}
            size={size ? size : 'large'}
            onChange={(event, values, reason) => {
              if (reason === "clear") {
                onChange(null);
                return;
              }
              let object = {
                id: values?.id,
                [optionLabel]: values?.[optionLabel],
              };
              onChange(object || null);
              if (customOnChange) {
                if (onChangeNeededFields) {
                  let fieldsObject = {}
                  onChangeNeededFields.map((item) => {
                    fieldsObject[item] = values?.[item];
                  })
                  let neededObject = {
                    ...fieldsObject,
                    ...object
                  }
                  customOnChange(neededObject);
                } else {
                  customOnChange(object);
                }
              }
            }}
            onBlur={(event) => {
              if (
                typeof event.target.value === "string" &&
                typeof value !== "object"
              ) {
                onChange(null);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={required ? label + " *" : label}
                variant="outlined"
                onChange={(event) => {
                  onChange(event.target.value);
                }}

                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                  },
                }}
                InputLabelProps={{
                  // shrink: true 
                }}
                error={errors[name] ? true : false}
                helperText={
                  errors[name]?.type === "serverError"
                    ? errors[name]?.message
                    : errors[name]?.type === "required"
                      ? label + " required"
                      : helperText ? helperText : ''
                }
              />
            )}
          />
        );
      }}
    />
  );
}
const filter = createFilterOptions();
export function QuickAdddropDown({
  optionLabel,
  control,
  name,
  required,
  options,
  label,
  api,
  fields,
  errors,
  setValue: setValueAutoComplete,
  // component,
}) {
  const [open, toggleOpen] = React.useState(false);
  const [quickAddSucess, setQuickAddSucess] = useState(false);
  const {
    handleSubmit,
    setError,
    formState: { errors: errorsQuickAdd },
    reset,
    control: controlQuickAdd,
  } = useForm({ mode: "onChange" });

  const handleClose = () => {
    toggleOpen(false);
    reset();
  };
  const HandleOnSubmit = (data) => {
    axiosClient
      .post(api, data)
      .then((res) => {
        if (res.status === 200) {
          setQuickAddSucess(true);
          setValueAutoComplete(name, res.data); //React hook forms method
          toggleOpen(false);
          options.push(res.data);
          reset();
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 400) {
          let fieldErrors = error.response.data.errorMessages;
          Object.keys(fieldErrors).forEach((key) => {
            setError(fieldErrors[key].fieldName, {
              type: "serverError",
              message: fieldErrors[key].message,
            });
          });
          return false;
        } else {
        }
      });
  };
  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={{
          required: required,
        }}
        render={({
          field: { onChange, value },
        }) => {
          return (
            <Autocomplete
              freeSolo
              value={value}
              options={options || []}
              inputValue={value?.[optionLabel] ?? value ?? ""}
              onChange={(event, newValue, reason) => {
                if (typeof newValue === "string") {
                  setTimeout(() => {
                    toggleOpen(true);
                  });
                } else if (newValue && newValue.inputValue) {
                  toggleOpen(true);
                } else if (reason === "clear") {
                  onChange(null);
                  return;
                } else {
                  let object = {
                    id: newValue?.id,
                    name: newValue?.[optionLabel],
                  };
                  onChange(object);
                }
              }}
              onInputChange={(event, newInputValue, reason) => {
                if (reason === "clear") {
                  onChange(null);
                  return false;
                }
                onChange(newInputValue);
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (params.inputValue !== "") {
                  filtered.push({
                    inputValue: params.inputValue,
                    // name: `Add "${params.inputValue}"`,
                    name: `Add new ${label}`,
                  });
                }
                return filtered;
              }}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue;
                }
                return option[optionLabel];
              }}
              getOptionSelected={(option, value) => {
                return option.id === value.id;
              }}
              renderOption={(props, option) => (
                <>
                  <li {...props}>{option[optionLabel]}</li>
                </>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={required ? label + " * " : label}
                  variant="outlined"
                  size="large"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      //borderRadius: "8px",
                    },
                  }}
                  error={errors[name] ? true : false}
                  helperText={
                    errors[name]?.type === "serverError"
                      ? errors[name]?.message
                      : errors[name]?.type === "required"
                        ? label + " is required"
                        : ""
                  }
                />
              )}
            />
          );
        }}
      />
      <AlertPopup
        success={quickAddSucess}
        method={setQuickAddSucess}
        variant="success"
        message={` A new ${label} has been added `}
      />

      <FormDialogBox
        open={open}
        //component={component}
        handleClose={handleClose}
        fields={fields}
        HandleOnSubmit={HandleOnSubmit}
        handleSubmit={handleSubmit}
        errors={errorsQuickAdd}
        control={controlQuickAdd}
        //setValue={setValue}
        //dialogValue={dialogValue || {}}
        //optionLabel={optionLabel}
        label={label}
      />
    </>
  );
}
//
function FormDialogBox({
  open,
  handleClose,
  //optionLabel,
  fields,
  HandleOnSubmit,
  errors,
  handleSubmit,
  control,
  label,
  // component,
}) {
  // setValue(optionLabel , dialogValue[optionLabel])
  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
        <DialogTitle>Add a new {label} </DialogTitle>
        <DialogContent>
          <form key={2}>
            <Stack
              direction="column"
              spacing={2}
              sx={{ pt: 2 }}
            >
              {fields?.names.map((item) => {
                if (item.type === "text") {
                  return (
                    <InputText
                      control={control}
                      errors={errors}
                      name={item.name}
                      required={true}
                      label={item.label}
                    />
                  );
                } else if (item.type === "dropdown") {
                  return (
                    <SelectInput
                      required={true}
                      control={control}
                      errors={errors}
                      options={Object.values(item.options)[0]}
                      optionLabel={item.optionLabel}
                      name={item.name}
                      label={item.label}
                    />
                  );
                } else if (item.type === "checkbox") {
                  return (
                    <InputCheckbox
                      required={false}
                      control={control}
                      errors={errors}
                      name={item.name}
                      label={item.label}
                      checked={true}
                    />
                  );
                }
              })}
            </Stack>
          </form>
          {/* {component} */}
        </DialogContent>
        <DialogActions>
          <Stack direction="row" spacing={1}>
            <Button
              onClick={handleSubmit(HandleOnSubmit)}
              size="small"
              variant="contained"
            >
              Submit
            </Button>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={handleClose}
            >
              Close
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
}

export function Dropdown({
  required,
  control,
  errors,
  name,
  options,
  label,
  size,
  helperText,
  customOnChange
}) {
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: required,
      }}
      render={({
        field: { onChange, value },
      }) => {
        return (
          <FormControl
            size={size ? size : "large"}
            fullWidth
            error={errors[name] ? true : false}
          >
            <InputLabel>
              {required ? label + " *" : label}
            </InputLabel>
            <Select
              value={value || ""}
              label={required ? label + " *" : label}
              name={name}
              onChange={(event) => {
                onChange(event.target.value);
                if (customOnChange) {
                  customOnChange(event.target.value);
                }
              }}
              sx={{ borderRadius: '8px' }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {options?.map((data) => {
                if (!data.value) {
                  return <MenuItem value={data}>{data}</MenuItem>;
                } else {
                  return <MenuItem value={data.value}>{data.name}</MenuItem>;
                }
              })}
            </Select>
            <FormHelperText>
              {errors[name]?.type === "serverError"
                ? errors[name]?.message
                : errors[name]?.type === "required"
                  ? label + " required"
                  : errors[name]?.type === "pattern"
                    ? errors[name]?.message
                    : errors[name]?.type === "min"
                      ? errors[name]?.message
                      : errors[name]?.type === "oneOf"
                        ? errors[name]?.message
                        : helperText ? helperText : ''}
            </FormHelperText>
          </FormControl>
        );
      }}
    />
  );
}


export function MultipleSelect({
  control,
  name,
  required,
  options,
  optionLabel,
  label,
  errors,
  customOnChange,
  helperText,
  optionBraces,
  optionBracesAction,
}) {
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: required,
      }}
      render={({
        field: { onChange, value, onBlur },
      }) => {
        return (
          <Autocomplete
            options={options || []}
            multiple
            getOptionLabel={(option) => {
              return option[optionLabel] ? option[optionLabel] : "";
            }}
            disableCloseOnSelect
            value={value || []}
            fullWidth={true}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(e, values) => {
              onChange(values || null);
              if (customOnChange) {
                customOnChange(values);
              }
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {/* {option[optionLabel]}  */}
                {option[optionLabel]} {optionBracesAction ? (optionBracesAction(option[optionBraces]) !== undefined ? "(" + optionBracesAction(option[optionBraces]) + ")" : '') : optionBraces && option[optionBraces] !== undefined ? "(" + option[optionBraces] + ")" : ''}
              </li>
            )}
            onBlur={onBlur}
            renderInput={(params) => (
              <TextField
                {...params}
                label={required ? label + " * " : label}
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                  },
                }}
                InputLabelProps={{
                  //shrink: true 
                }}
                error={errors[name] ? true : false}
                helperText={
                  errors[name]?.type === "serverError"
                    ? errors[name]?.message
                    : errors[name]?.type === "required"
                      ? label + " is required"
                      : errors[name]?.type === "typeError"
                        ? label + " is required"
                        : helperText ? helperText : ''
                }
              />
            )}
          />
        );
      }}
    />
  );
}

export function MultipleCustomSelect({
  control,
  name,
  required,
  options,
  optionLabel,
  label,
  errors,
  customOnChange,
  helperText,
  optionBraces,
  optionBracesAction,
}) {
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: required,
      }}
      render={({
        field: { onChange: hookFormChange, value, onBlur },
      }) => {
        return (
          <Autocomplete
            options={options || []}
            multiple
            freeSolo={false}
            getOptionLabel={(option) => {
              if (typeof option === 'string') {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option[optionLabel];
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            disableCloseOnSelect
            value={value || []}
            fullWidth={true}
            isOptionEqualToValue={(option, value) => option[optionLabel] === value[optionLabel]}
            onChange={(e, values) => {

              let ModifiedValueswithName = values.map((item) => {
                console.log(item);
                return { [optionLabel]: item[optionLabel] }
                // return Object?.values(item).map((item2) => { return { [optionLabel]: item2 } })
              });

              hookFormChange(ModifiedValueswithName || null);
              if (customOnChange) {
                let modifiedValues = values?.map((item) => {
                  return { name: item[optionLabel] }
                })
                // console.log(modifiedValues)
                customOnChange(modifiedValues);
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some(
                (option) => inputValue === option[optionLabel]
              );
              if (inputValue !== '' && !isExisting) {
                filtered.push({
                  [optionLabel]: inputValue,
                  // AddTitle: `Add "${inputValue}"`,
                });
              }
              return filtered;
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {/* {option[optionLabel]}  */}
                {option[optionLabel]} {optionBracesAction ? (optionBracesAction(option[optionBraces]) !== undefined ? "(" + optionBracesAction(option[optionBraces]) + ")" : '') : optionBraces && option[optionBraces] !== undefined ? "(" + option[optionBraces] + ")" : ''}
              </li>
            )}
            onBlur={onBlur}
            renderInput={(params) => (
              <TextField
                {...params}
                label={required ? label + " * " : label}
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                  },
                }}
                InputLabelProps={{
                  //shrink: true 
                }}
                error={errors[name] ? true : false}
                helperText={
                  errors[name]?.type === "serverError"
                    ? errors[name]?.message
                    : errors[name]?.type === "required"
                      ? label + " is required"
                      : errors[name]?.type === "typeError"
                        ? label + " is required"
                        : helperText ? helperText : ''
                }
              />
            )}
          />
        );
      }}
    />
  );
}

export function InputCheckbox({ control, name, required, label, checked, customOnChange, helperText, disabled }) {
  return (
    <>
      <FormControl error={required} >
        <Controller
          control={control}
          name={name}
          rules={{
            required: required,
          }}
          defaultValue={checked ? true : false}
          render={({ field, fieldState: { error } }) => (
            <>
              <FormControlLabel
                label={required === true ? label + ' *' : label}
                control={
                  <Checkbox
                    {...field}
                    checked={field.value === true ? true : false}
                    // onChange={field.onChange}
                    onChange={(e) => {
                      field.onChange(e.target.checked)
                      if (customOnChange) {
                        customOnChange(e.target.checked)
                      }
                    }}
                    disabled={disabled ? true : false}
                  />
                }
              />
              {error && (
                <>
                  <FormHelperText>
                    {error?.type === "required" ? label + " required" : helperText ? helperText : ''}
                  </FormHelperText>
                </>
              )}
            </>
          )}
        />
      </FormControl>
    </>
  );
}
export function InputSwitch({ control, name, required, label, checked, customOnChange, helperText, disabled }) {
  return (
    <>
      <FormControl error={required} >
        <Controller
          control={control}
          name={name}
          rules={{
            required: required,
          }}
          defaultValue={checked ? true : false}
          render={({ field, fieldState: { error } }) => (
            <>
              <FormControlLabel
                label={required === true ? label + ' *' : label}
                control={
                  <Switch
                    {...field}
                    checked={field.value === true ? true : false}
                    // onChange={field.onChange}
                    onChange={(e) => {
                      field.onChange(e.target.checked)
                      if (customOnChange) {
                        customOnChange(e.target.checked)
                      }
                    }}
                    disabled={disabled ? true : false}
                  />
                }
              />
              {error && (
                <>
                  <FormHelperText>
                    {error?.type === "required" ? label + " required" : helperText ? helperText : ''}
                  </FormHelperText>
                </>
              )}
            </>
          )}
        />
      </FormControl>
    </>
  );
}

// export function RadioCheckBox(props){
//     return(
//     <>
//         <Controller
//             name={props?.name}
//             control={props?.control}
//             rules={{
//                 required: props?.required,
//             }}
//             render={({ field: { onChange, value } }) => (
//             <RadioGroup value={value} onChange={onChange}>
//                 {options.map((option) => (
//                 <FormControlLabel
//                     key={option.value}
//                     value={option.value}
//                     control={<Radio />}
//                     label={option.label}
//                 />
//             ))}
//             </RadioGroup>
//         )}
//     />
//       {label && <label>{label}</label>}
//     />
// </>
//     )
// }

export function AlertMessage({ success, method, variant, message, seconds, customAction }) {
  var closeAlertId;
  React.useEffect(() => {
    if (success) {
      closeAlertId = setTimeout(() => {
        method(false);
        if (customAction) {
          customAction()
        }
      }, seconds ? seconds : 5000);
    }
  }, [success])

  return (
    <>
      <Collapse in={success}  >
        <Box sx={{ width: "100%", p: 1 }} >
          <Alert
            severity={variant ?? "success"}
            sx={{ alignItems: "center" }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  method(false);
                  clearTimeout(closeAlertId);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          // sx={{ mb: 1 }}
          >
            <strong> {message} </strong>
          </Alert>
        </Box>
      </Collapse>
    </>
  );
}
export function AlertPopup({ success, method, message, variant, seconds, customAction }) {
  var closeAlertId;
  React.useEffect(() => {
    if (success) {
      closeAlertId = setTimeout(() => {
        method(false);
        if (customAction) {
          customAction()
        }
      }, seconds ? seconds : 5000);
    }
  }, [success])

  return (
    <>
      <Stack>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={success}
          sx={{ maxWidth: 600 }}
          onClose={() => {
            method(false);
            clearTimeout(closeAlertId);
            if (customAction) {
              customAction()
            }
          }}
        >
          <Alert
            onClose={() => {
              method(false);
              clearTimeout(closeAlertId);
              if (customAction) {
                customAction()
              }
            }}
            variant="filled"
            severity={variant}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}
/*
// /This is old version
export function MuiDatePickerOld(props) {
  const optionLabel = props?.optionLabel;
  return (
    <Controller
      control={props?.control}
      name={props?.name}
      rules={{
        required: props?.required,
      }}
      render={({
        field: { onChange: hookChange, value, onBlur },
        fieldState: { error },
        formState: { isSubmitted, isSubmitSuccessful, isValid },
      }) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={props?.label}
              value={dayjs(value)}
              onChange={(newValue) => {
                let newDate = dayjs(newValue);
                let formateddate = newDate.format("YYYY-MM-DD");
                hookChange(formateddate);
              }}
              fullWidth={true}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={props.errors[props.name] ? true : false}
                  helperText={params?.inputProps?.placeholder}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      //borderRadius: "10px",
                    },
                  }}
                  InputLabelProps={{
                    //shrink: true
                  }}
                />
              )}
            />
          </LocalizationProvider>
        );
      }}
    />
  );
}*/
// This is beta/ new version
export function MuiDatePicker({
  control,
  errors,
  name,
  required,
  label,
  readOnly,
  minDate,
  maxDate,
  disableWeekend,
  helperText,
  custom, // Custom value
  customOnChange, // If you dont want to use the react hook form and you want it for your customstate you can pass it as like this
}) {
  const isWeekend = (date) => {
    const day = date.day();
    return day === 0 || day === 6;
  };
  let FieldObjects = {
    minDate: "",
    maxDate: "",
  };
  if (minDate) {
    if (minDate === "today") {
      FieldObjects.minDate = dayjs();
    } else if (minDate === "tomorrow") {
      FieldObjects.minDate = dayjs().add(1, "day");
    } else if (minDate === "18year") {
      FieldObjects.minDate = dayjs(new Date()).subtract(18, "year");
    } else {
      FieldObjects.minDate = dayjs(minDate);
    }
  }
  if (maxDate) {
    if (maxDate === "today") {
      FieldObjects.maxDate = dayjs();
    } else if (maxDate === "tomorrow") {
      FieldObjects.maxDate = dayjs().add(1, "day");
    } else if (maxDate === "18year") {
      FieldObjects.maxDate = dayjs(new Date()).subtract(18, "year");
    } else {
      FieldObjects.maxDate = dayjs(maxDate);
    }
  }
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: required,
      }}
      //defaultValue = {dayjs()}
      render={({
        field: { onChange: hookFormChange, value },
      }) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(newValue) => {
                let newDate = dayjs(newValue);
                let formateddate = newDate.format("YYYY-MM-DD");
                hookFormChange(formateddate);
                if (customOnChange) {
                  customOnChange(formateddate);
                }
              }}
              {...FieldObjects}
              shouldDisableDate={disableWeekend ? isWeekend : null}
              value={
                value === undefined ? "" : custom ? dayjs(custom) : dayjs(value)
              }
              readOnly={readOnly == true ? true : false}
              label={required ? label + " * " : label}
              format="DD-MM-YYYY"
              fullWidth={true}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                },
              }}
              slotProps={{
                textField: {
                  // InputLabelProps: { shrink: true },
                  fullWidth: true,
                  helperText:
                    errors[name]?.type === "serverError"
                      ? errors[name]?.message
                      : errors[name]?.type === "required"
                        ? label + " required"
                        : errors[name]?.type === "pattern"
                          ? errors[name]?.message
                          : helperText ? helperText : '',
                  error: errors[name] ? true : false,
                },
              }}
            />
          </LocalizationProvider>
        );
      }}
    />
  );
}
export function MuiDateAndTimePicker({
  control,
  errors,
  name,
  required,
  label,
  readOnly,
  minDate,
  maxDate,
  disableWeekend,
  helperText,
  custom, // Custom value
  customOnChange, // If you dont want to use the react hook form and you want it for your customstate you can pass it as like this
}) {
  const isWeekend = (date) => {
    const day = date.day();
    return day === 0 || day === 6;
  };
  let FieldObjects = {
    minDate: "",
    maxDate: "",
  };
  if (minDate) {
    if (minDate === "today") {
      FieldObjects.minDate = dayjs();
    } else if (minDate === "tomorrow") {
      FieldObjects.minDate = dayjs().add(1, "day");
    } else if (minDate === "18year") {
      FieldObjects.minDate = dayjs(new Date()).subtract(18, "year");
    } else {
      FieldObjects.minDate = dayjs(minDate);
    }
  }
  if (maxDate) {
    if (maxDate === "today") {
      FieldObjects.maxDate = dayjs();
    } else if (maxDate === "tomorrow") {
      FieldObjects.maxDate = dayjs().add(1, "day");
    } else if (maxDate === "18year") {
      FieldObjects.maxDate = dayjs(new Date()).subtract(18, "year");
    } else {
      FieldObjects.maxDate = dayjs(maxDate);
    }
  }
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: required,
      }}
      //defaultValue = {dayjs()}
      render={({
        field: { onChange: hookFormChange, value },
      }) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              onChange={(newValue) => {
                console.log(newValue)
                let newDate = dayjs(newValue);
                let formateddate = newDate.format("YYYY-MM-DD hh:mm:ss");
                hookFormChange(formateddate);
                if (customOnChange) {
                  customOnChange(formateddate);
                }
              }}
              {...FieldObjects}
              shouldDisableDate={disableWeekend ? isWeekend : null}
              value={
                value === undefined ? "" : custom ? dayjs(custom) : dayjs(value)
              }
              readOnly={readOnly == true ? true : false}
              label={required ? label + " * " : label}
              // format="DD-MM-YYYY"
              format="DD-MM-YYYY hh:mm:ss"
              fullWidth={true}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                },
              }}
              slotProps={{
                textField: {
                  // InputLabelProps: { shrink: true },
                  fullWidth: true,
                  helperText:
                    errors[name]?.type === "serverError"
                      ? errors[name]?.message
                      : errors[name]?.type === "required"
                        ? label + " required"
                        : errors[name]?.type === "pattern"
                          ? errors[name]?.message
                          : helperText ? helperText : '',

                  error: errors[name] ? true : false,
                },
              }}
            />
          </LocalizationProvider>
        );
      }}
    />
  );
}

export function MuiTimePicker({
  control,
  errors,
  name,
  required,
  label,
  readOnly,
  helperText,
  custom,
    customOnChange,
    size
}) {

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: required,
      }}
      render={({
        field: { onChange: hookFormChange, value },
      }) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimeField
              onChange={(newValue) => {
                let newDate = dayjs(newValue);
                let formateddate = newDate.format("YYYY-MM-DD HH:mm:ss");
                // let formateddate = newDate.format("hh:mm A");
                hookFormChange(formateddate);
                if (customOnChange) {
                  customOnChange(formateddate);
                }
              }}
              value={
                value === undefined ? "" : custom ? dayjs(custom) : dayjs(value)
              }
              readOnly={readOnly == true ? true : false}
              label={required ? label + " * " : label}
              format="HH:mm:ss"
              fullWidth={true}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                },
              }}
              slotProps={{
                    textField: {
			size: size ? size :'large',
                  fullWidth: true,
                  helperText:
                    errors[name]?.type === "serverError"
                      ? errors[name]?.message
                      : errors[name]?.type === "required"
                        ? label + " required"
                        : errors[name]?.type === "pattern"
                          ? errors[name]?.message
                          : helperText ? helperText : '',

                  error: errors[name] ? true : false,
                },
              }}
            />
          </LocalizationProvider>
        );
      }}
    />
  );
}


export function MuiDrawer(props) {
  const { show, width, title, close, children } = props;
  const [state, setState] = React.useState({
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ right: open });
    if (!open) {
      close(open);
    }
  };
  React.useEffect(() => {
    if (show) {
      setState({ right: show });
    } else {
      setState({ right: show });
    }
  }, [show]);

  return (
    <>
      <Drawer
        anchor="right"
        open={state.right}
        onClose={toggleDrawer("right", false)}
      // variant="persistent"
      >
        <Box
          sx={{ width: width !== "" ? width : "auto" }}
          role="presentation"
        >
          <DialogTitle sx={{ background: '#03a9f4', color: '#fff' }}>{title}</DialogTitle>
          <Divider />
          <DialogContent>
            {children}
          </DialogContent>
          <Divider />
        </Box>
      </Drawer>
    </>
  );
}

export function RemoveUnwanted(dataObj, fields, del = null) {
  let removedObj = dataObj;
  let ObjectValues = Object.keys(removedObj);
  for (let field of fields) {
    ObjectValues.map((item2) => {
      if (item2 === field) {
        removedObj[field] = { id: dataObj[field]?.id }
      }
    })
  }
  if (del) {
    for (let arg of del) {
      delete removedObj[arg];
    }
  }
  return removedObj;
}
